<template>
	<section id="knowledge-base-search">
		<b-card no-body class="knowledge-base-bg text-center">
			<b-card-body class="card-body">
				<h2 class="text-primary">Welcome to Emoty admin panel</h2>
			</b-card-body>
			<div v-if="showVerification" class="card-body">
				<UserVerify />
			</div>
		</b-card>
	</section>
</template>
<script>
import { BCard, BCardBody } from 'bootstrap-vue';
import { mapState } from 'vuex';
import UserVerify from '@/views/user-verify/UserVerify.vue';

export default {
	name: 'Home',
	components: {
		BCard,
		BCardBody,
		UserVerify,
	},
	data() {
		return {
			verificationStatusCodes: [11, 12],
		};
	},
	computed: {
		...mapState('userManagement', ['userModules']),
		showVerification() {
			const e = this.userModules.error;
			if (e && this.verificationStatusCodes.includes(e.code)) return true;
			return false;
		},
	},
	created() {
		// this.getUserInfo();
	},
	methods: {},
};
</script>

<style lang="scss">
#knowledge-base-search {
	.text-primary {
		height: 30vh;
		line-height: 30vh;
	}
}
</style>
