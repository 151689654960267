<template>
	<div class="verify-wrapper">
		<BCard class="b-card">
			<div v-if="!mobileAlready" class="mobile-verification">
				<h2>Send code to mobile number</h2>
				<BButton
					v-if="!mobileSent"
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					type="submit"
					variant="primary"
					class="send-button"
					:disabled="mobileSent ? true : false"
					@click="sendVerificationMobile"
				>
					{{ mobileSent ? 'Mobile verification is sent' : 'Send' }}
				</BButton>
				<div v-if="mobileSent">
					<BButton
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						type="submit"
						variant="primary"
						@click="verifyMobile"
					>
						Verify
					</BButton>
					<b-form-input
						id=""
						v-model="verificationCode"
						class="input"
						type="number"
						placeholder="verification code"
					/>
				</div>
				<h2 v-if="timerEnabled" class="timer-wrapper">
					{{ `Resend in ${timeLeft}` }}
				</h2>
			</div>
			<div v-else>
				<h2>Your mobile is already verified</h2>
			</div>
			<div v-if="emailAlready" class="email-verification">
				<h2>Send verification link to my email</h2>
				<BButton
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					type="submit"
					variant="primary"
					:disabled="emailSent ? true : false"
					@click="sendVerificationEmail"
				>
					{{ emailSent ? 'Email verification is sent' : 'Send' }}
				</BButton>
			</div>
			<div v-else>
				<h2 class="mt-1">Your email is already verified</h2>
			</div>
		</BCard>
	</div>
</template>

<script>
import { BCard, BButton, BFormInput } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import Ripple from 'vue-ripple-directive';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
	components: {
		BCard,
		BButton,
		BFormInput,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			emailSent: false,
			mobileSent: false,
			timerEnabled: false,
			time: null, // in seconds
			timer: null,
			verificationCode: null,
		};
	},

	computed: {
		...mapState('accountSettings', ['userData']),
		...mapState('userManagement', ['userModules']),
		...mapState('vendorRegistration', [
			'verification',
			'verificationStatus',
			'verificationEmail',
		]),

		timeLeft() {
			return `${this.minutes}:${this.seconds}`;
		},
		emailAlready() {
			if (this.verificationEmail) {
				if (this.verificationEmail.error.code === 18) {
					return false;
				}
				return true;
			}
			return true;
		},
		mobileAlready() {
			const ue = this.userModules.error?.code;
			const mv = this.verificationStatus?.data?.isSuccessfullyVerified;
			if (this.verificationStatus && mv) {
				return true;
			}
			if (ue !== 12) {
				return true;
			}
			return false;
		},
		minutes() {
			return String(Math.floor(this.time / 60)).padStart(2, '0');
		},
		seconds() {
			return String(this.time % 60).padStart(2, '0');
		},
	},
	watch: {
		...mapActions('userManagement', ['getUserModules']),
		timerEnabled() {
			this.timer = setInterval(this.decrementOrAlert, 1000);
		},
		'verificationStatus.data': function (value) {
			console.log(value);
			if (!value.isSuccessfullyVerified) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Verification Code is invalid',
						icon: 'EditIcon',
						variant: 'danger',
					},
				});
			} else {
				// this.timerEnabled = false;
				this.getUserInfo();
				this.timerEnabled = false;
				this.time = null;
				this.mobileSent = false;
				if (this.userModules.error.code === 9) {
					router.go(-1);
				}
				clearInterval(this.timer);
			}
		},
	},
	methods: {
		...mapActions('vendorRegistration', [
			'mobileVerificationCode',
			'mobileVerification',
			'emailVerificationLink',
			'clearErrors',
		]),
		...mapActions('accountSettings', ['getUserInfo']),

		decrementOrAlert() {
			if (this.time > 0) {
				// eslint-disable-next-line no-plusplus
				this.time--;
				return;
			}
			this.timerEnabled = false;
			this.mobileSent = false;
			clearInterval(this.timer);
		},
		sendVerificationEmail() {
			this.emailVerificationLink();
			if (!this.emailAlready) {
				this.emailSent = true;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Email verification is sent',
						icon: 'EditIcon',
						variant: 'success',
					},
				});
				if (this.userModules.error.code === 10) {
					router.go(-1);
				}
			}
		},
		verifyMobile() {
			if (this.verificationCode) {
				this.mobileVerification({
					code: this.verificationCode,
					identifier: this.verification.data.identifier,
				});
			}
		},
		sendVerificationMobile() {
			this.mobileVerificationCode();
			this.timerEnabled = true;
			this.time = 300;
			this.mobileSent = true;
		},
	},
};
</script>
<style>
@import './user.css';
</style>
